import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { sendFeedback } from '../functions/feedback';
import { getSessionDetails, getTokenDetails } from '../functions/userSession';
import { UserType } from '../types/user';
import { RoutePaths } from './route-paths';
import { decodeToken } from 'react-jwt';
import { TokenType } from '../types/user';
import { useAppDispatch } from 'store/hooks';
import { setClientId } from 'store/slices/user';

export const PrivateUserRoute = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  //  Remove this line after auth is complete

  const token = getTokenDetails();
  if (!token) {
    sendFeedback('Login to continue', 'error');
    return <Navigate to={RoutePaths.LOGIN} replace />;
  }

  try {
    const decodedToken = decodeToken<TokenType>(token);

    if (!decodedToken || decodedToken.clientId !== 'customer-access') {
      return <Navigate to={RoutePaths.LOGIN} replace />;
    }
  } catch (error) {
    return <Navigate to={RoutePaths.LOGIN} replace />;
  }

  // If everything is okay, render the protected component
  return children;
};

export const PrivateVendorRoute = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  //  Remove this line after auth is complete
  // return children;
  const dispatch = useAppDispatch();
  const token = getTokenDetails();

  if (!token) {
    sendFeedback('Login to continue', 'error');
    return <Navigate to={RoutePaths.LOGIN} replace />;
  }

  try {
    const decodedToken = decodeToken<TokenType>(token);

    if (decodedToken) {
      dispatch(setClientId(decodedToken.clientId));
    }

    if (!decodedToken || decodedToken.clientId !== 'supplier-client') {
      return <Navigate to={RoutePaths.LOGIN} replace />;
    }
  } catch (error) {
    return <Navigate to={RoutePaths.LOGIN} replace />;
  }

  // If everything is okay, render the protected component
  return children;
};

export const ProtectedUserRoute = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const currentUser: UserType | null = getSessionDetails();
  console.log(currentUser, 'currentUser');
  const token = getTokenDetails();
  if (currentUser && Object.keys(currentUser).length) {
    sendFeedback('You are already logged in');
    return <Navigate to={RoutePaths.USER_DASHBOARD} replace />;
  }

  // if (token) {
  //   sendFeedback('You are already logged in');
  //   return <Navigate to={RoutePaths.USER_DASHBOARD} replace />;
  // }
  return children;
};

export const ProtectedVendorRoute = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const currentUser = getSessionDetails();

  if (currentUser && Object.keys(currentUser).length) {
    sendFeedback('You are already logged in');
    return <Navigate to={RoutePaths.PROVIDER_BUSINESS_INFO} replace />;
  }
  return children;
};

export const utilityMethod = {
  obscureEmail(email: string | null): string {
    if (!email) return '';
    const [username, domain] = email.split('@');
    const [domainName, domainExtension] = domain.split('.');

    const obscuredUsername =
      username.length > 1
        ? username.slice(0, 1) + '*'.repeat(username.length - 1)
        : '*'.repeat(username.length);

    return `${obscuredUsername}@${domainName}.${domainExtension}`;
  },
};

export const toTitleCase = (str: string) => {
  return str
    .toLowerCase()
    .trim() // Remove leading and trailing whitespace
    .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export function base64ToBlob(
  base64String: string,
  contentType: string = ''
): Blob {
  try {
    const byteCharacters = atob(base64String);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  } catch (error) {
    console.error('Error in base64ToBlob:', error);
    throw error;
  }
}

// Helper function to capitalize the first letter of each word
export const capitalizeString = (str: string) => {
  if (!str) return '';
  return (
    str
      // Convert the entire string to lowercase
      .toLowerCase()
      // Split the string into an array of words
      .split(' ')
      // Capitalize the first letter of each word
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      // Join the words back into a string
      .join(' ')
  );
};
