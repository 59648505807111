import React from 'react';
import { Link } from 'react-router-dom';

function NoSavingsYet() {
  return (
    <div className="flex flex-col justify-center items-center gap-y-2">
      <figure>
        <img src="/svgs/saveMoney.svg" alt="piggy_ban_icon" />
      </figure>
      <div className="flex flex-col gap-y-2 justify-center items-center mb-4 lg:mb-0">
        <h1 className="text-l font-bold">No Savings Yet!</h1>
        <p>Reach your individual savings goals with 12 p.a </p>
      </div>
      <Link
        to="/"
        className="py-2 px-4 bg-[#EFA005] text-white hover:bg-[#e9b959] rounded-md"
      >
        Start New Savings
      </Link>
    </div>
  );
}

export default NoSavingsYet;
