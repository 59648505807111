import React, { useState } from 'react';
import BeginSavingsTarget from '../../../pages/user/dashboard/savings/BeginSavingsTarget';
import FinishSavingsTarget from '../../../pages/user/dashboard/savings/FinishSavingsTarget';
import { SavingsFormData } from '../../../types';
import { useNavigate } from 'react-router-dom';
import { appAxios } from 'api/axios';
import { getTokenDetails } from 'functions/userSession';
import { sendCatchFeedback, sendFeedback } from 'functions/feedback';
import { decodeToken } from 'react-jwt';
import { TokenType } from 'types/jwt';
import AuthAnimatedModal from 'components/shared/modal/AuthAnimatedModal';

const SavingsForms: React.FC = () => {
  const token = getTokenDetails();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const decodedToken = decodeToken<TokenType>(token!);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const customerId = decodedToken?.customerId;

  const [formValues, setFormValues] = useState<SavingsFormData>({
    customerId: customerId,
    goalName: '',
    targetAmount: 0,
    monthlyContribution: 0,
    startDate: '',
    endDate: '',
    preferredTime: '',
    autoSave: true,
    status: 'pending',
    savingSource: '',
    savingCategory: '',
    interestTagentSaving: true,
    targetBreakBeforeEndDate: true,
    savingsTime: '12:10',
    frequency: '',
    cardDetails: {
      cardNumber: '',
      expiryMonth: '',
      expiry_year: '',
      cvv: '',
    },
  });

  const handleNext = () => {
    if (currentStep < 2) {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => prev - 1);
    }
  };

  const handleFormData = (data: Partial<SavingsFormData>) => {
    setFormValues((prevData) => ({ ...prevData, ...data }));
  };

  const formatToISO8601 = (date: string) => {
    const parsedDate = new Date(date);
    return !isNaN(parsedDate.getTime()) ? parsedDate.toISOString() : '';
  };

  const handleProceed = () => {
    setIsModalOpen(false);
    navigate(-1);
  };

  const handleDataSubmit = async () => {
    try {
      setLoading(true);

      const formattedFormValues = {
        ...formValues,
        startDate: formatToISO8601(formValues?.startDate),
        endDate: formatToISO8601(formValues?.endDate),
        savingsTime: formValues?.preferredTime,
      };

      const { data } = await appAxios.post(
        `/create-savings-goal`,
        formattedFormValues
      );
      console.log(data, 'data');
      if (data && data.statusCode === 201) {
        // sendFeedback(data?.message, 'success');
        setLoading(false);
        setIsModalOpen(true);
      } else {
        setLoading(false);
        sendCatchFeedback(data);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const steps = [
    <BeginSavingsTarget
      handleNext={handleNext}
      setFormValues={handleFormData}
      formValues={formValues}
    />,
    <FinishSavingsTarget
      handleBack={handleBack}
      handleNext={handleDataSubmit}
      formValues={formValues}
      setFormValues={handleFormData}
      handleDataSubmit={handleDataSubmit}
    />,
  ];

  console.log(formValues, 'formVal');

  return (
    <div className="mt-4">
      <div>
        {currentStep === 0 && (
          <BeginSavingsTarget
            handleNext={handleNext}
            setFormValues={handleFormData}
            formValues={formValues}
          />
        )}
        {currentStep === 1 && (
          <FinishSavingsTarget
            handleBack={handleBack}
            handleNext={handleDataSubmit}
            formValues={formValues}
            setFormValues={handleFormData}
            handleDataSubmit={handleDataSubmit}
          />
        )}
      </div>
      <AuthAnimatedModal
        isOpen={isModalOpen}
        onClose={handleProceed}
        onConfirm={handleProceed}
        title="Savings Goal Submitted!"
        btnText="Proceed"
        content={<p>You have successfully created a savings goal.</p>}
      />
    </div>
  );
};

export default SavingsForms;
